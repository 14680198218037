<template>
     <div class="page__errir__wrapper padding__top__botton"> 
           <div class="container">
               <div class="row">
                   <div class="col-lg-12">
                       <div class="error__content">
                            <h2>PAGE NOT FOUND!</h2>
                            <p>The page you were looking for appears to have been moved, deleted or does not exist.</p>
                            <router-link to="/">Back To Homepage</router-link>
                       </div>
                   </div>
               </div>
           </div>
        </div>
</template>
<script>
export default {
  name: 'Error',
  components: { 
    },
  data(){
      return{}
  },
     
}
</script>