<template>
   <div class="contact__container padding__top__botton"> 
      <div class="container">
          <div class="row">
              <div class="col-lg-10 offset-lg-2 mx-auto">
                  <div class="row">
                      <div class="col-lg-6">
                          <div class="contact__left">
                              <div class="section__title__container mb-3">
                                  <h5 class="section__subtitle">Contact Us</h5>
                                  <h2 class="section__title">Keep in Touch</h2>
                              </div>
                              <form>
                                  <div class="form-group">
                                      <input type="text" class="form-control mb-4" id="name" aria-describedby="emailHelp" placeholder="Your Name"/>
                                  </div>
                                  <div class="form-group">
                                      <input type="email" class="form-control mb-4" id="email" placeholder="Your Email" />
                                  </div>
                                  <div class="form-group">
                                      <textarea class="form-control mb-4" name="textarea" id="textarea" cols="30" rows="5"></textarea>
                                  </div>
                                  <button type="submit" class="btn page__contact__btn">Send Message</button>
                              </form>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div> 
</template>
<script>
export default {
  name: 'Contact',
  components: {  },
  data(){
      return{
      }
  },
  props: {
    msg: String
  },
  methods: {}
     
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

