<template>
  <div class="subscriber__wrapper padding__top__botton margin__top">
      <div class="container">
        <div class="row">
              <div class="col-lg-12 text-center">
                <div class="subscriber__title__container">
                    <h2 class="subscriber__title">Subscribe Newsletter</h2>
                    <p class="subscriber__contnet">
                    Enter your email address to register to our newsletter delivered on a regular basis!
                    </p>
                </div>
                <form class="form-inline">
                    <div class="form-group">
                        <input type="email" class="form-control" id="inputPassword2" placeholder="Enter mail"/>
                        <button type="submit" class="btn">subscribe</button>
                    </div>
                </form>
              </div>
          </div>
      </div>
      <div class="subscriber__con">
          <img :src="sidecolor" alt="sidecolor"/>
      </div>
  </div>    
</template>

<script>
import sidecolor from '../../assets/images/sidecolor.svg'
export default {
  name: 'Subscriber',
  components: {  },
  data(){
      return{
          sidecolor:sidecolor
      }
  },
  props: {
    msg: String
  },
  methods: {}
     
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

