import Home from '../pages/home/home'
import ContactPage from '../pages/ContactPage/ContactPage'


import Error from '../pages/Error/Error'

export const routes=[
    {
        path:'/',
        component:Home,
    },
    
    {
        path:'/contact',
        name:'Contact',
        component:ContactPage,
    },
    {
        path:'/error',
        name:'Error',
        component:Error,
    },
    

]