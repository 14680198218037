<template>
    <div>
        <breadcrumb title="contact" title1=""/>
        <div class="contact__wrapper">
            <Contact/>
        </div> 
    </div> 
</template>
<script>

import Contact from '../../components/Contact/Contact'

export default {
  name: 'ContactPage',
  components: {
  
        Contact
    },
  data(){
      return{}
  },
     
}
</script>
